import { Header, Tabs, Group, Button, Divider, Menu } from '@mantine/core';
import { GetDeviceStatus } from '../logic/GetDeviceStatus';
import { Link } from 'react-router-dom';
import {TomSWLogo} from "./TomSWLogo";
import { IconHome2, IconUser, IconCodeCircle, IconLogin } from '@tabler/icons';

function ProcessTabs(highlight: string) {
    return (
        <><Tabs color="indigo" variant="pills" defaultValue={highlight}>
            <Tabs.List position="center">
                <Link style={{textDecoration: 'none'}} to="/"><Tabs.Tab value="home">Home</Tabs.Tab></Link>
                <Link style={{textDecoration: 'none'}} to="/about-me"><Tabs.Tab value="about">About Me</Tabs.Tab></Link>
                <Link style={{textDecoration: 'none'}} to="/portfolio"><Tabs.Tab value="portfolio">Portfolio</Tabs.Tab></Link>
                <Tabs.Tab value="photography">Photography</Tabs.Tab>
            </Tabs.List>
        </Tabs>
        <Group>
            <Link style={{textDecoration: 'none'}} to="/login"><Button color="indigo">Login</Button></Link>
            <Button color="indigo" disabled>Register</Button>
        </Group></>
    );
}

function HeaderTabs() {
    if (GetDeviceStatus()) {
        return (
            <><Menu shadow="md" width={"100vh"}>
                <Menu.Target>
                    <Button variant="subtle" color="indigo">
                        Menu
                    </Button>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Label>Navigation Menu</Menu.Label>
                    <Link to={'/'}><Menu.Item icon={<IconHome2 size={14}/>}>Home</Menu.Item></Link>
                    <Link to={'/about-me'}><Menu.Item icon={<IconUser size={14}/>}>About Me</Menu.Item></Link>
                    <Link to={'/portfolio'}><Menu.Item icon={<IconCodeCircle size={14}/>}>Portfolio</Menu.Item></Link>
                    <Menu.Item icon={<IconCodeCircle size={14}/>}>Photography</Menu.Item>
                    <Menu.Divider/>
                    <Menu.Label>Authentication</Menu.Label>
                    <Link style={{textDecoration: 'none'}} to="/login"><Menu.Item icon={<IconLogin size={14}/>}>Login</Menu.Item></Link>
                    <Menu.Item icon={<IconLogin size={14}/>}>Register</Menu.Item>
                </Menu.Dropdown>
            </Menu></>
        );
    }
    else {
        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            return ProcessTabs('home');
        }
        else if (window.location.pathname === '/about-me') {
            return ProcessTabs('about');
        }
        else if (window.location.pathname === '/portfolio') {
            return ProcessTabs('portfolio');
        }
        else {
            return ProcessTabs('');
        }
    }
}

export function NavMenu() {
    return (
        <><Header height={60} p="xs">{<Group position="apart"><><TomSWLogo width={50} height={40} /><HeaderTabs /></></Group>}</Header><Divider size="md" /></>
    );
}