import { Text, Container, Group, Space } from '@mantine/core';
import { IconBrandDiscord } from '@tabler/icons';

import { useStyles } from "../models/styles/FooterStyle";
import {TomSWLogo} from "./TomSWLogo";

interface FooterLinksProps {
    data: {
        title: string;
        links: { label: string; link: string }[];
    }[];
}

export function Footer({ data }: FooterLinksProps) {
    const { classes } = useStyles();

    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Text<'a'>
                key={index}
                className={classes.link}
                component="a"
                href={link.link}
                onClick={(event) => event.preventDefault()}
            >
                {link.label}
            </Text>
        ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <TomSWLogo width={60} height={50} />
                    <Text size="xs" color="dimmed" className={classes.description}>
                        Personal website and client portal
                    </Text>
                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>
            <Container className={classes.afterFooter}>
                <Text color="dimmed" size="sm">
                    © 2021 - {new Date().getFullYear()} tomsw.uk, All rights reserved.
                </Text>

                <Group spacing={0} className={classes.social} position="right" noWrap>
                    <IconBrandDiscord size={18} stroke={1.5} />
                    <Space w="xs"></Space>
                    <Text size="xs" color="dimmed" className={classes.description}>
                        tom_sw
                    </Text>
                </Group>
            </Container>
        </footer>
    );
}