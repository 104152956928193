import { Title, Text, Container, Button, Overlay } from '@mantine/core';
import { Link } from 'react-router-dom';

import { useStyles } from "../../models/styles/HeroHeaderStyle";

export function HeroHeader() {
    const { classes } = useStyles();

    return (
        <div className={classes.wrapper}>
            <Overlay color="#000" opacity={0.65} zIndex={1} />

            <div className={classes.inner}>
                <Title className={classes.title}>
                    Thomas Brown:{' '}
                    <Text component="span" inherit className={classes.highlight}>
                        Student
                    </Text>
                </Title>

                <Container size={640}>
                    <Text size="md" className={classes.subtext}>
                        Studying BSc Applied Software Engineering
                    </Text>
                </Container>

                <div className={classes.controls}>
                    <Link style={{textDecoration: 'none'}} to="/about-me">
                        <Button className={classes.control} variant="white" size="lg">
                            About Me
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}