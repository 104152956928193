import {useState, useRef} from "react";
import Turnstile from "react-turnstile";
import { IconLock, IconAt } from '@tabler/icons-react';

import {SystemMemoryProps} from "../../models/SystemMemoryProps";
import {NavMenu} from "../../components/GetNavMenu";
import {Divider, PasswordInput, Input, Button, Title} from "@mantine/core";
import {Footer} from "../../components/GetFooter";
import {FormContainer} from "../../components/form/FormContainer";

export function Login({memory}: SystemMemoryProps) {
    const verification = useState("null")

    const inputs = {
        email: useRef(null),
        password: useRef(null)
    }

    const form = (
        <>
            <Title align="center">Login</Title>
            <Input.Label required size={"lg"}>Email Address</Input.Label>
            <Input
                placeholder="Your account's email"
                icon={<IconAt size={"1rem"}/>}
                variant="filled"
                radius="md"
                size="lg"
                ref={inputs.email}
            /><br/>
            <Input.Label required size={"lg"}>Password</Input.Label>
            <PasswordInput
            placeholder="Password"
            icon={<IconLock size={"1rem"}/>}
            variant="filled"
            radius="md"
            size="lg"
            ref={inputs.password}
            /><br/>
            <center>
                <Turnstile
                    sitekey={memory[0].CFSiteKey}
                    onVerify={(token) => {
                        verification[1](token);
                    }}/><br/>
                <Button variant="light" color="indigo" radius="md" size="md" uppercase>Login</Button>
            </center>
            <br/><Divider size={2}></Divider><br/>
            <Button variant="subtle" color="indigo">Verify Account</Button>
            <Button variant="subtle" color="indigo">Forgot Password</Button>
        </>
    )

    return (
        <>
            <NavMenu></NavMenu>
            {FormContainer(form)}
            <Footer data={[]}></Footer>
        </>
    )
}